import React from "react"
import ReactPlayer from "react-player/lazy"
import plan from "../../images/plan-well.svg"
import learn from "../../images/learn-well.svg"
import dowell from "../../images/do-well.svg"

export default function pillsAndVideo() {
  return (
    <div>
      <div className="learning_layout text-center">
        <h1 className="learning_title">
          <p>Learning Doesn't</p> Stop At School
        </h1>
        <div className="row">
          <div className="col-xl-4 my-2 py-2">
            <span className="tooltip_learn py-2">
              <img
                src={plan}
                alt="alt text"
                className="btnimg"
                width="54px"
                height="54px"
              />
              <span>Plan Well </span>
            </span>
          </div>

          <div className="col-xl-4 my-2 py-2">
            <span className="tooltip_learn py-2">
              <img
                src={learn}
                alt="alt text"
                className="btnimg"
                width="54px"
                height="54px"
              />
              <span>Learn Well </span>
            </span>
          </div>

          <div className="col-xl-4 my-2 py-2">
            <span className="tooltip_learn py-2">
              <img
                src={dowell}
                alt="alt text"
                className="btnimg"
                width="54px"
                height="54px"
              />
              <span>Do Well </span>
            </span>
          </div>
        </div>
      </div>
      <br />
      <div className="col-xs-12 col-md-10 mx-auto justify-content-center">
        <div className="embed-responsive embed-responsive-16by9">
          <ReactPlayer
            className="react-player"
            url="https://youtu.be/f9AAppwVxsE"
            width="100%"
            height="100%"
            controls
            playing
            light="https://i.ytimg.com/vi/f9AAppwVxsE/sddefault.jpg"
          />
        </div>
        <br />
      </div>
    </div>
  )
}
