import React from 'react'
import study from "../../images/landing-page.jpg"
export default function image() {
    return (
        <img
        src={study}
        alt="Tata Class Edge"
        width="90%"
        height="90%"
        className="img-fluid"
      />
    )
}
